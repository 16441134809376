import React from 'react'

interface Props {
  items: React.ReactNode[]
  gridCols: 'md:grid-cols-4' | 'md:grid-cols-3' | 'md:grid-cols-2'
  className?: string
}

const Swiper: React.FC<Props> = ({ className, items, gridCols }) => {
  return (
    <div
      className={
        'flex flex-col flex-nowrap items-center justify-between' +
        (className ? ` ${className}` : '')
      }
    >
      <div
        className={`${gridCols} flex w-[100%] flex-none snap-x snap-mandatory flex-nowrap overflow-x-auto pb-3 md:grid md:gap-6`}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={
              'mx-[10px] w-[70%] flex-none snap-center md:mx-[auto] md:w-full'
            }
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Swiper
